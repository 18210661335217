<template>
  <div class="engine" v-if="content">
        <div class="video1" style="text-align: center;padding-top: 64px">
              <div class="img4"
                   :style="{'background-image':content.productStyle.img4?`url(${content.productStyle.img4})`:''}"
              >
                    <div class="btn" @click="dialogVisible=true">
                      <span>观看视频</span>
                      <img src="../../assets/right-btn.png" alt="">
                    </div>
              </div>
        </div>
        <div class="img1">
          <img :src="content.productStyle.img1" width="100%" height="320px" alt="">
        </div>
        <div class="img2" style="text-align: center;position: relative">
          <video id="video1"
                 :src="content.productStyle.img2"
                 @ended="end"
                 muted width="1200px" height="600px"
                 ref="video1"
          ></video>
          <i v-show="showVideo1"
             class="el-icon-refresh-left"
             style="font-size: 24px;cursor: pointer"
             @click="playVideo1"
          ></i>
        </div>
        <div class="icon-list" v-animate-onscroll.repeat="{down:'swing'}">
            <div class="icon-item"
                 v-for="(item,index) in content.productStyle.icons"
                 :key="index"
            >
                <div><img :src="item.icon" alt="" width="64px" height="64px"></div>
                <div class="title">{{item.title}}</div>
            </div>
        </div>
        <div class="info">
              <div class="title">{{content.productStyle.content1.title}}</div>
              <div class="text">{{content.productStyle.content1.description}}</div>
              <div class="url-box">
                <video autoplay="autoplay" loop="loop" muted width="1200px" height="600px">
                  <source :src="content.productStyle.content1.resourceUrl" type="video/mp4">
                </video>
              </div>
              <div class="info-list">
                  <div class="info-list-item"
                       v-for="(item,index) in content.productStyle.content1.list"
                  >
                      <div class="title">{{item.title}}</div>
                      <div class="text">{{item.description}}</div>
                  </div>
              </div>
        </div>
        <div class="info">
            <div class="title">适用场景</div>
            <div class="info-content animation"
                 v-for="(item,index) in content.productStyle.list1"
                 v-show="activeIndex==index"
                 :key="index"
            >
                <div class="text">{{item.description}}</div>
                <div style="margin-top: 50px">
                  <img width="1200px" height="600px" :src="item.resourceUrl" alt="">
                </div>
            </div>
            <div class="tools-box">
                <div class="tools-item"
                     v-for="(item,index) in content.productStyle.list1"
                     :class="activeIndex==index?'active':''"
                     :key="index"
                     @click="activeIndex=index"
                >
                  {{item.title}}
                </div>
            </div>
    </div>
        <div class="info">
          <div class="title">应用部署</div>
          <div style="margin-top: 50px">
            <img width="1200px" height="510px" :src="content.productStyle.img3" alt="">
          </div>
        </div>
        <div class="info">
            <div class="title">硬件参数</div>
            <div style="margin-top: 50px">
                <img :src="item.resourceUrl" alt=""
                     v-for="(item,index) in content.productStyle.list2"
                     :key="index"
                     width="1200px"
                     height="350px"
                     class="animation"
                     v-show="listActive==index"
                >
            </div>
            <div class="tools-box">
                <div class="tools-item"
                     v-for="(item,index) in content.productStyle.list2"
                     :key="index"
                     :class="listActive==index?'active':''"
                     @click="listActive=index"
                >
                    {{item.title}}
                </div>
            </div>
    </div>
        <el-dialog
            :visible.sync="dialogVisible"
            top="0"
            width="100%"
            :before-close="handleClose">
          <div>
            <video :src="content.productStyle.video1" autoplay="autoplay" loop="loop" muted width="1200px" height="600px"></video>
          </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "engine",
  data(){
    return {
      content:null,
      activeIndex:0,
      listActive:0,
      dialogVisible:false,
      showVideo1:true,
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
  },
  mounted() {
    window.addEventListener('scroll',this.playVideo)
  },
  destroyed() {
    window.removeEventListener("scroll",this.playVideo);
  },
  methods:{
    getDetail(){
          this.$get('/website/product/style3/'+this.$route.query.id).then((res)=>{
              this.content=res.data;
          }).catch((err)=>{

          })
    },
    handleClose(){
      this.dialogVisible=false;
    },
    playVideo(){
      let scrollTop=document.documentElement.scrollTop
      let video1Top = document.querySelector('#video1').offsetTop-400;
      if(800<=scrollTop&&scrollTop<=1200){
        this.$refs.video1.play();
        this.showVideo1=false;
      }
    },
    playVideo1(){
      this.$refs.video1.play();
      this.showVideo1=false;

    },
    end(){
        this.showVideo1=true;
    }
  }
}
</script>
<style lang="scss">
.el-dialog{
  height: 100vh;
  background-color: #232526;
  .el-dialog__headerbtn .el-dialog__close{
    font-size: 24px;
    color: #FFFFFF;
  }
  .el-dialog__body{
    display: flex;
    justify-content: center;
    >div{
      margin-top: 80px;
    }
  }
}
@keyframes leftToRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  0% {
    transform: translateY(60px) scale(0.6);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.swing {
  animation: swing 1s;
}
.animation {
  animation: leftToRight 1s;
}
</style>
<style scoped lang="scss">
.engine{
  background: black;
  padding-bottom: 50px;
  color: #fff;
  .img4{
    width: 100%;
    height: 816px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
      width: 140px;
      height: 36px;
      line-height: 36px;
      font-weight: 400;
      border: 1px solid rgba(228, 228, 228, 1);
      border-radius:22px;
      cursor: pointer;
      img{
        vertical-align: middle;
        width: 14px;
        height: 14px;
      }
    }
    .btn:hover{
      background-color: rgba(255,255,255,0.12);
    }
  }
  .img1{
    width: 100%;
    height: 320px;
  }
  .icon-list{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-item{
          width: 25%;
          text-align: center;
          margin-top: 30px;
    }
    .icon-item:nth-child(4n){

    }
  }
  .info,.parameter{
    width: 1200px;
    margin: 0 auto;
    .title{
      font-size: 34px;
      text-align: center;
      margin-top: 128px;
    }
    .text{
      margin: 0 auto;
      margin-top: 20px;
      width: 800px;
      text-align: center;
      line-height: 25px;
    }
    .url-box{
      margin-top: 30px;
    }
    .info-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .info-list-item{
        width: 50%;
        padding: 10px 50px;
        background: transparent;
        transition: all 0.5s;
        opacity: 1;
        .text{
          color: #797979;
          width: 100%;
        }
      }
      .info-list-item:hover{
        transform: scale(1.2);
        .text{
          color: #fff;
        }
      }
    }
    .info-content{

    }
    .tools-box{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #3F3F3F;
      .tools-item{
        padding: 5px 10px;
        color: #5A5A5A;
        border-top: 1px solid transparent;
        cursor: pointer;
      }
      .tools-item.active{
        color: #fff;
        border-top: 1px solid #fff;
      }
    }
  }
  .canshu{

  }
}
</style>
